import React from "react"
import { Link } from "gatsby"

import "./styles.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"

class AGB extends React.Component {
    render() {
        return (
            <Layout>
                <SEO title="AGB" />
                <section className="section legal-text">
                    <h1 class="title">Allgemeine Geschäftsbedingungen</h1>
                    <br />
                    <h2 class="subtitle">1. Geltungsbereich</h2>
                    <p>1.1 Diese allgemeinen Geschäftsbedingungen regeln die Vertragsbeziehung zwischen dem/der Teilnehmer*in und mir, d.h. Hand-K-Macht, Inhaberin Karin Zeitler, Pettenkoferring 4, 86633 Neuburg a.d. Donau, über die Teilnahme an von mir angebotenen Näh- und Handarbeitskurse.</p>
                    <p>1.2 In ihrem Anwendungsbereich gelten für die Geschäftsbeziehung zwischen dem/der Teilneh-mer*in und mir ausschließlich diese Schulungsbedingungen in ihrer zum Zeitpunkt der Anmeldung gültigen Fassung. Abweichende Bedingungen von Teilnehmenden erkenne ich nicht an, es sei denn, ich habe ausdrücklich ihrer Geltung zugestimmt.</p>
                    <br />
                    <h2 class="subtitle">2. Leistungsbeschreibung, Kursinhalte</h2>
                    <p>Ich biete den Teilnehmenden insbesondere Näh- sowie andere Schulungskurse für Erwachsene rund um das Thema Nähen und Handarbeiten an. Das Angebot beinhaltet sowohl Gruppen- als auch Einzelkurse. Die konkreten Inhalte und Laufzeiten der Kurse ergeben sich aus dem jeweils aktuellen, auf meiner Website abrufbaren Programm bzw. auf Basis individueller Vereinbarung. Die Kurse geben einen Einblick in die Themen Nähen und Handarbeit und können ohne Vorkenntnisse gebucht werden. Da ich auf die spezifischen Wünsche der jeweiligen Gruppe / Teilnehmenden eingehen möchten, kann es zu kleineren Abweichungen vom Programm kommen. Bei Individualkursen werden die Inhalte vor Kursbeginn abgestimmt und ggf. auf die Bedürfnisse des/der Teilnehmenden angepasst. Ein bestimmter Erfolg des Kurses wird jedoch nicht versprochen.</p>
                    <br />
                    <h2 class="subtitle">Anmeldung (Vertragsschluss)</h2>
                    <p>3.1 Die Anmeldung zu unseren Kursen kann schriftlich mit dem auf meiner Internetseite zur Verfügung stehenden Anmeldeformular oder bei mir vor Ort erfolgen.</p>
                    <p>3.2 Nach Zugang der vom/von der Teilnehmer*in ausgefüllten und unterschriebenen Anmeldung entscheide ich über die Annahme des Angebots. Bei Annahme versenden ich eine Anmeldebestätigung. Der Vertrag kommt mit dem Zugang der Anmeldebestätigung zustande. Mit der Anmeldebestätigung erhält der/die Teilnehmer*in eine Rechnung über die Kursgebühr.</p>
                    <p>3.3 Die Anmeldung ist für den jeweiligen Kurs verbindlich und kann nur für einen vollständigen Kurs erfolgen. Eine Teilbuchung von aus mehreren Einheiten bestehenden Kursen als auch eine Umbuchung ist nicht möglich.</p>
                    <br />
                    <h2 class="subtitle">4. Durchführung der Kurse</h2>
                    <p>4.1 An unseren Gruppenkursen können bis zu vier Personen teilnehmen. Die Kurse finden ab einer Mindestanzahl von zwei Teilnehmer*innen statt.</p>
                    <p>4.2 Soweit nicht anders ausdrücklich vereinbart, finden die Kurse zu den im Programm angegebenen Terminen in meinem Kursraum statt.</p>
                    <p>4.3 Ein Gruppenkurs sowie einzelne seiner Einheiten werden ab mind. zwei Teilnehmer*innen durchgeführt. Besteht der Kurs aus verschiedenen Einheiten und ist kein/e Teilneh-mer*in anwesend, liegt es in meinem Ermessen, für die Einheit an einem Ersatztermin anzubieten oder den Kurs dem Programm entsprechend fortzuführen und die Einheit entfallen zu lassen.</p>
                    <p>4.4 Auf Wunsch wird dem/der Teilnehmer*in bei eine Teilnahmebestätigung über die Kursteilnahme ausgestellt.</p>
                    <br />
                    <h2 class="subtitle">5. Kursgebühr und Zahlungsbedingungen</h2>
                    <p>5.1 Die Höhe der Kursgebühr hängt von dem jeweiligen Kurs ab und kann auf meiner Seite unter "Nähkurse" eingesehen werden.</p>
                    <p>5.2 Die Zahlung ist nach Zugang der Rechnung sofort fällig, spätestens aber nach Ablauf von acht Tagen. Sofern die Kursgebühr EUR 1.000,00 übersteigt, ist die Vereinbarung einer Ratenzahlung möglich. Zahlungen sind nur im Wege der Banküberweisung möglich.</p>
                    <p>5.3 Bei Zahlung der Kursgebühr ist der Name von dem/der Teilnehmer*in sowie die von uns vergebene Rechnungsnummer anzugeben. Soweit nicht anders vereinbart sind Zahlungen ausschließlich auf das in unserer Rechnung angegebene Bankkonto zu leisten.</p>
                    <p>5.4 Ein Aufrechnungsrecht steht dem/der Teilnehmer*in nur zu, wenn seine Gegenansprüche rechtskräftig festgestellt oder von uns unbestritten oder anerkannt sind.</p>
                    <p>5.5 Bei nicht rechtzeitiger Zahlung bin ich insbesondere berechtigt, von unserem Leistungsverweigerungsrecht Gebrauch zu machen oder vom Vertrag zurückzutreten und den Platz anderweitig zu besetzen.</p>
                    <br />
                    <h2 class="subtitle">6. Material</h2>
                    <p>Materialkosten sind in der Kursgebühr nicht enthalten. Material ist von den Kursteilnehmer*innen selbst zu beschaffen und am Kurstag mitzubringen.</p>
                    <br />
                    <h2 class="subtitle">7. Vertragslaufzeit, Kündigung</h2>
                    <p>7.1 Die Vertragslaufzeit entspricht der jeweiligen Kursdauer und endet mit dem Ablauf des Kurses.</p>
                    <p>7.2 Eine ordentliche Kündigung ist ausgeschlossen. Ziff. 8 bleibt hiervon unberührt. Das Recht beider Parteien zur Kündigung aus wichtigem Grund bleibt unberührt. Eine Kündigung bedarf der Textform.</p>
                    <br />
                    <h2 class="subtitle">8. Absage/Verschiebung von Kursen oder Kurseinheiten</h2>
                    <p>8.1 Bei Vorliegen eines wichtigen Grundes, z.B. bei eigener Erkrankung, ist der/die Teilnehmer*in berechtigt, seine/ihre Teilnahme an einem Kurs abzusagen und diese zu einem mit mir abzustimmenden Termin nachzuholen, wenn uns die Absage spätestens 48 Stunden vor Kursbeginn zugeht. Eine Erstattung der Kursgebühr erfolgt in dem Fall der Verschiebung nicht. Im Übrigen gelten die gesetzlichen Bestimmungen.</p>
                    <p>8.2 Ich bin zu einer Absage eines Kurses oder Kurseinheiten aus wichtigem Grund berechtigt, insbesondere infolge von von mir nicht zu vertretenen Umstände (z.B. Erkrankung des/der Dozent*in). Ich werde den/die Teilnehmer*in hierüber umgehend informieren</p>
                    <p>8.3 Im Fall einer Absage des vollständigen Kurses wird eine bereits geleistete Kursgebühr vollständig erstattet. Betrifft die Absage einzelne Kurseinheiten bin ich unter den vorstehenden Bedingungen zu einer Absage dieser Einheiten oder zur Verschiebung auf einen angemessenen Ersatztermin berechtigt. Im Falle einer Absage von einzelnen Einheiten wird die Kursgebühr anteilig entsprechend dem Verhältnis der durchgeführten zu abgesagten Einheiten erstattet.</p>
                    <p>8.4 Zur Vermeidung einer Absage behalten ich mir vor, den zeitlichen Umfang des Kurses bei gleichbleibenden Inhalten in angemessenem Rahmen zu reduzieren.</p>
                    <p>8.5 Für vergebliche Aufwendungen oder sonstige Nachteile, die dem/der Teilnehmer*in durch die Absage bzw. Verschiebung entstehen komme ich nur nach Maßgabe der Ziff. 10 (Haftung) auf.</p>
                    <br />
                    <h2 class="subtitle">9. Haftung</h2>
                    <p>9.1 Ich hafte uneingeschränkt nach Maßgabe der gesetzlichen Bestimmungen für Schäden von Teilnehmenden, die durch vorsätzliches oder grob fahrlässiges Verhalten von mir oder meiner Erfüllungsgehilfen verursacht worden sind sowie für Personenschäden und Schäden nach dem Produkthaftungsgesetz.</p>
                    <p>9.2 Im Übrigen ist meine Haftung für Schadensersatzansprüche &#8211; gleich aus welchem Rechtsgrund &#8211; nach Maßgabe der folgenden Bestimmungen beschränkt, soweit sich nicht aus einer von mir übernommenen Garantie etwas anderes ergibt.</p>
                    <p>9.3 Für leicht fahrlässig verursachte Schäden haften ich nur, soweit sie auf der Verletzung vertragswesentlicher Pflichten (Kardinalpflichten) beruhen. Kardinalpflichten sind solche Vertragspflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglichen und auf deren Einhaltung der/die Teilnehmer*in vertrauen durfte. Soweit ich hiernach für einfache Fahrlässigkeit hafte, ist die Haftung auf den typischerweise vorhersehbaren Schaden beschränkt.</p>
                    <p>9.4 Die Bestimmungen der vorstehenden Absätze gelten entsprechend auch für eine Begrenzung der Ersatzpflicht für vergebliche Aufwendungen (§ 284 BGB).</p>
                    <p>9.5 Die vorstehenden Haftungsbeschränkungen gelten auch zugunsten unserer Erfüllungsgehilfen.</p>
                    <br />
                    <h2 class="subtitle">10. Datenschutz</h2>
                    <p>Ich verarbeite die vom/von der Teilnehmer*in mitgeteilten Daten zum Zwecke des Vertragsschlusses und seiner Durchführung, einschließlich Rechnungsstellung und Buchführung (Rechtsgrundlage: Art. 6 Abs. 1 S. 1 lit. b DSGVO).</p>
                    <br />
                    <h2 class="subtitle">11. Nutzungsrechte</h2>
                    <p>Die dem/der Teilnehmer*in von mir zur Verfügung gestellten Werke, z.B. Zeichnungen, Texte, dürfen von dem/der Teilnehmer*in nur für private, nicht berufliche Zwecke genutzt werden. Eine Veröffentlichung, einschließlich der öffentlichen Zugänglichmachung im Internet, sowie eine Vervielfältigung sind nicht gestattet.</p>
                    <br />
                    <h2 class="subtitle">12. Anwendbares Recht, Erfüllungsort und Gerichtsstand</h2>
                    <p>12.1 Es gilt ausschließlich deutsches Recht unter Ausschluss des UN-Kaufrechts.</p>
                    <p>12.2 Erfüllungsort ist der Sitz meines Gewerbebetriebs.</p>

                </section>
            </Layout >
        );

    }
}

export default AGB
